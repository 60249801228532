import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, List, Row, Select } from "antd";
import { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { useQuery, useQueryClient } from "react-query";
import { SubjectPlanDTO } from "../../Api";
import ConfirmationModal from "../../Containers/ConfirmationModal";
import Layout from "../../Containers/Layout";
import CustomButton from "../../CustomComponents/CustomButton";
import CustomCard from "../../CustomComponents/CustomCard";
import {
    DeleteChangeRequest, DeleteSpecialChangeRequest,
    GetChangeRequest,
    GetDestinationSubject,
    GetRemainingChanges,
    GetSourceSubjects,
    PostChangeRequest,
    PostSpecialChangeRequest
} from "../../Requests/change-subject-requests";
import { theme } from "../../theme";
import { DroppableStyles } from "../../utils/constants";
import { getUserId, useIsMasterStudent } from "../../utils/utilFunctions";
import { NOTIFICATION_TYPES, openNotification } from "../Notifications/NotificationsUtils";
import styles from "./Optionals.module.scss";

const { Option } = Select;

const ChangeSubject = () => {
    const { t } = useTranslation();
    const [editable, setEditable] = useState([true]);
    const [editable2, setEditable2] = useState(new Array<boolean>());
    const [cardColor, setCardColor] = useState([theme.lightGray]);
    const [choices, setChoices] = useState(new Array<any>());
    const [choices2, setChoices2] = useState(new Array<any>());
    const [requests, setRequests] = useState([{}] as any);
    const [specialRequests, setSpecialRequests] = useState([{}] as any);
    const [options, setOptions] = useState(new Array<any>());
    const [destinations, setDestinations] = useState(new Array<any>());
    const [source, setSource] = useState(new Array<any>());
    const [source2, setSource2] = useState(new Array<any>());
    const [change, setChange] = useState([false]);
    const [change2, setChange2] = useState(new Array<boolean>());
    const [save, setSave] = useState([false]);
    const [save2, setSave2] = useState(new Array<boolean>());
    const [available, setIsAvailable] = useState(true);
    const queryClient = useQueryClient();

    const isMasterStudent = useIsMasterStudent();


    const openChoicesErrorNotification = (_error: any) => {
        openNotification(
            t('optionals.error'),
            t('optionals.fetchChoicesError'),
            NOTIFICATION_TYPES.ERROR
        );
    }

    const { data, isLoading: loading1 } = useQuery(['getSourceSubjects'],
        () => {
            return GetSourceSubjects();
        }, {
        onError: (error) => {
            const errorWithResponse = error as {status?: number};
            if (errorWithResponse.status) {
                const statusCode = errorWithResponse.status;
                if (statusCode === 403) {
                    setIsAvailable(false);
                } else {
                    openChoicesErrorNotification(error);
                }
            } else {
                openChoicesErrorNotification(error);
            }
        },
        refetchOnWindowFocus: false,
        refetchInterval: Infinity,
        onSuccess: (data) => {
            if (data) {
                let newOptions = [...options];
                newOptions[0] = data;
                setOptions(newOptions);
                setIsAvailable(true);
            }
        }
    });

    const { data : remainingChanges } = useQuery(['getRemainingChanges'], () => { return GetRemainingChanges() }, { refetchOnWindowFocus: false });

    const { data: changeRequests, isLoading: loading } = useQuery(['getChangeRequests'],
        () => {
            return GetChangeRequest();
        }, {
        onError: openChoicesErrorNotification,
        refetchOnWindowFocus: false,
        refetchInterval: Infinity,
        onSuccess: async (data) => {
            if (data) {
                let newChoices = [...choices2];
                let newSources2 = [...source2];
                setRequests(data.changeRequests);
                setSpecialRequests(data.specialChangeRequests);
                let newEdits2 = [...editable2];
                if (data.specialChangeRequests?.length)
                    for (let i = 0; i < data.specialChangeRequests?.length; i++) {
                        newChoices[i] = data.specialChangeRequests[i].message;
                        setChoices2(newChoices);
                        newSources2[i] = data.specialChangeRequests[i].sourceSubjectPlan;
                        setSource2(newSources2);
                        newEdits2.push(true);
                        setEditable2(newEdits2);
                    }
                let newSources = [...source];
                let newChoices2 = [...choices];
                let newEdits = [...editable];
                let newCardColor = [...cardColor];

                if (data.changeRequests) {
                    const promises = new Array<Promise<SubjectPlanDTO[]>>();

                    for (let i = 0; i < data.changeRequests.length; i++) {
                        // @ts-ignore
                        newChoices2[i] = data.changeRequests[i].destinationSubjectPlans;
                        setChoices(newChoices2);
                        newSources[i] = data.changeRequests[i].sourceSubjectPlan;
                        setSource(newSources);
                        newEdits.push(true);
                        setEditable(newEdits);
                        newCardColor[i] = theme.lightGray;
                        setCardColor(newCardColor);
                        promises.push(GetDestinationSubject(newSources[i].semester, newSources[i].type, newSources[i].credits));
                    }

                    Promise.all(promises).then(results => setDestinations(results)).catch((_error) => {
                        openNotification(
                            t('optionals.error'),
                            t('optionals.destinationSubjectFetchedError'),
                            NOTIFICATION_TYPES.ERROR
                        );
                    });
                }
            }
        }
    });

    const handleOnDragEnd = (result: any, index: number) => {
        let newChoices = [...choices];
        const items = Array.from(choices[index]);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        newChoices[index] = items;
        setChoices(newChoices);
    };

    const onSelect = (value: any, index: any) => {
        let aux;
        for (let i = 0; i < options[0].length; i++)
            if (value === options[0][i].id)
                aux = options[0][i];

        let newSource = [...source];
        newSource[index] = aux;
        setSource(newSource);

        let newSaves = [...save];
        newSaves[index] = false;
        setSave(newSaves);

        let newChoices = [...choices];
        newChoices[index] = [];
        setChoices(newChoices);

        GetDestinationSubject(aux.semester, aux.type, aux.credits).then((response) => {

            let newDestinations = [...destinations];
            newDestinations[index] = response;
            newDestinations[index] = newDestinations[index].filter((item: any) => item.id !== value);
            setDestinations(newDestinations);

        }).catch((_error) => {
            openNotification(
                t('optionals.error'),
                t('optionals.destinationSubjectFetchedError'),
                NOTIFICATION_TYPES.ERROR
            );
        });

        let newChanges = [...change];
        newChanges[index] = true;
        setChange(newChanges);

        let newEdits = [...editable];
        newEdits[index] = false;
        setEditable(newEdits);

        let newCardColor = [...cardColor];
        newCardColor[index] = theme.white;
        setCardColor(newCardColor);
    }

    const onSpecialSelect = (value: any, index: any) => {
        let newChanges = [...change2];
        newChanges[index] = true;
        setChange2(newChanges);

        let newEdits = [...editable2];
        newEdits[index] = false;
        setEditable2(newEdits);

        let aux;
        for (let i = 0; i < options[0].length; i++)
            if (value === options[0][i].id)
                aux = options[0][i];

        let newSource = [...source2];
        newSource[index] = aux;
        setSource2(newSource);
    }

    const onSelect2 = (value: any, index: number) => {
        let newSaves = [...save];
        newSaves[index] = true;
        setSave(newSaves);

        let auxPreferences = [];
        let aux;
        let pos = -1;
        for (let i = 0; i < destinations[index].length; i++) {
            if (value === destinations[index][i].id) {
                aux = destinations[index][i];
                pos = i;
            }
            auxPreferences.push(destinations[index][i]);
        }
        if (pos !== -1)
            auxPreferences.splice(pos, 1);

        let newChoices = [...choices];
        newChoices[index] = newChoices[index].concat(aux);
        setChoices(newChoices);
    }

    const handleSave = (value: any) => {
        let body = {
            id: requests[value].id ? requests[value].id : null,
            studentId: getUserId(),
            sourceSubjectPlan: {
                id: source[value].id,
                name: source[value].name,
                series: source[value].series
            },
            destinationSubjectPlans: choices[value],

        }
        PostChangeRequest(body).then(() => {
            openNotification(
                t('optionals.saveOption'),
                t('optionals.saveChangesMessage'),
                NOTIFICATION_TYPES.SUCCESS
            );
            let newEdits = [...editable];
            newEdits[value] = true;
            setEditable(newEdits);

            let newCardColor = [...cardColor];
            newCardColor[value] = theme.lightGray;
            setCardColor(newCardColor);

            let newSaves = [...save];
            newSaves[value] = false;
            setSave(newSaves);

        }).catch((_error: any) => {
            openNotification(
                t('optionals.error'),
                t('optionals.saveChangesError'),
                NOTIFICATION_TYPES.ERROR
            );
        });
    }

    const handleSpecialSave = (value: any) => {
        let body = {
            id: specialRequests[value].id ? specialRequests[value].id : null,
            studentId: getUserId(),
            sourceSubjectPlan: {
                id: source2[value].id,
                name: source2[value].name,
                series: source2[value].series
            },
            message: choices2[value],

        }
        PostSpecialChangeRequest(body).then(() => {
            openNotification(
                t('optionals.saveOption'),
                t('optionals.saveChangesMessage'),
                NOTIFICATION_TYPES.SUCCESS
            );
            let newEdits = [...editable2];
            newEdits[value] = true;
            setEditable2(newEdits);

            let newSaves = [...save2];
            newSaves[value] = false;
            setSave2(newSaves);

        }).catch((_error: any) => {
            openNotification(
                t('optionals.error'),
                t('optionals.saveChangesError'),
                NOTIFICATION_TYPES.ERROR
            );
        });
    }

    const handleEdit = (value: any) => {
        let newEdits = [...editable];
        newEdits[value] = false;
        setEditable(newEdits);

        let newCardColor = [...cardColor];
        newCardColor[value] = theme.white;
        setCardColor(newCardColor);

        let newSaves = [...save];
        newSaves[value] = true;
        setSave(newSaves);
    }

    const handleSpecialEdit = (value: any) => {
        let newEdits = [...editable2];
        newEdits[value] = false;
        setEditable2(newEdits);

        let newSaves = [...save2];
        newSaves[value] = true;
        setSave2(newSaves);
    }

    const addNewRequest = () => {
        let newChanges = [...change];
        newChanges.push(false);
        setChange(newChanges);

        let newSaves = [...save];
        newSaves.push(false);
        setSave(newSaves);

        let newEdits = [...editable];
        newEdits.push(true);
        setEditable(newEdits);

        let newChoices = [...choices];
        newChoices.push(new Array<any>());
        setChoices(newChoices);

        let newRequests = [...requests];
        newRequests.push({});
        setRequests(newRequests);
    };

    const addSpecialRequest = () => {
        let newChanges = [...change2];
        newChanges.push(false);
        setChange2(newChanges);

        let newSaves = [...save2];
        newSaves.push(false);
        setSave2(newSaves);

        let newEdits = [...editable2];
        newEdits.push(true);
        setEditable2(newEdits);

        let newRequests = [...specialRequests];
        newRequests.push({});
        setSpecialRequests(newRequests);
    };

    const deleteRequest = (value: any, index: number) => {
        let newChoices = [...choices];
        newChoices.splice(index, 1);
        setChoices(() => {
            return newChoices
        });

        let newSaves = [...save];
        newSaves.splice(index, 1);
        setSave(() => {
            return newSaves
        });

        let newEdits = [...editable];
        newEdits.splice(index, 1);
        setEditable(() => {
            return newEdits
        });

        let newChanges = [...change];
        newChanges.splice(index, 1);
        setChange(() => {
            return newChanges
        });

        let newSources = [...source];
        newSources.splice(index, 1);
        setSource(() => {
            return newSources
        });

        let newRequests = [...requests];
        newRequests.splice(index, 1);
        setRequests(() => {
            return newRequests
        });

        if (value) {
            DeleteChangeRequest(value).then(() => {
            }).catch((_error) => {
                openNotification(
                    t('optionals.error'),
                    t('optionals.deleteChangeRequestError'),
                    NOTIFICATION_TYPES.ERROR
                );
            }
            );
        }
    };

    const deleteSpecialRequest = (value: any, index: number) => {
        let newSaves = [...save2];
        newSaves.splice(index, 1);
        setSave2(() => {
            return newSaves
        });

        let newEdits = [...editable2];
        newEdits.splice(index, 1);
        setEditable2(() => {
            return newEdits
        });

        let newSources = [...source2];
        newSources.splice(index, 1);
        setSource2(() => {
            return newSources
        });

        let newChanges = [...change2];
        newChanges.splice(index, 1);
        setChange2(() => {
            return newChanges
        });

        let newChoices = [...choices2];
        newChoices.splice(index, 1);
        setChoices2(() => {
            return newChoices
        });

        let newRequests = [...specialRequests];
        newRequests.splice(index, 1);
        setSpecialRequests(() => {
            return newRequests
        });

        if (value) {
            DeleteSpecialChangeRequest(value).then().catch(
                (_error) => {
                    openNotification(
                        t('optionals.error'),
                        t('optionals.deleteChangeRequestError'),
                        NOTIFICATION_TYPES.ERROR
                    );
                }
            );
        }
    };

    const handleDelete = (item: any, index: number) => {
        let pos = -1;
        for (let i = 0; i < choices[index].length; i++) {
            if (choices[index][i].id === item.id) {
                pos = i;
            }
        }
        if (pos !== -1) {
            let newChoices = [...choices];
            const items2 = Array.from(choices[index]);
            items2.splice(pos, 1);
            newChoices[index] = items2;
            setChoices(newChoices);
        }
    }

    const handleChangeInputs = (event: any, index: any) => {
        event.persist();
        let newChoices = [...choices2];
        newChoices[index] = event.target.value;
        setChoices2(newChoices);

        let newSaves = [...save2];
        newSaves[index] = true;
        setSave2(newSaves);
    };

    const renderRequests = () => {
        return requests.map((request: any, index: any) =>
            <div key={index}>
                <Row gutter={[8, 16]} key={index} style={{ marginBottom: '2em', justifyContent: 'center', background: theme.lightBlue, padding: "2em", borderRadius: "0.5em" }}>
                    <Col span={window.innerWidth > 535 ? 10 : 24}>
                        <h4>Cerere de schimbare {index + 1}:</h4>
                        {options[0] && options[0].length > 0 && <Select
                            className={styles.customSelect}
                            onChange={(value: any) => onSelect(value, index)}
                            placeholder={t('optionals.option')}
                            disabled={editable[index] && choices[index]?.length > 0}
                            value={requests[index] ? source[index]?.name : t('optionals.option')}
                        >
                            {options[0]?.filter((e: any) => source.concat(source2).filter((c: any) => c?.id === e.id).length === 0)?.map((option: any) => (
                                <Option key={option.id}>{option.name}</Option>
                            ))}
                        </Select>}
                        { !isMasterStudent && <h5>*poți schimba o materie cu o altă materie din același an și semestru, care are cel puțin tot atatea puncte credit ca cea pe care o schimbi și este de acelați tip (D - D, S - S)</h5>}
                        { !isMasterStudent && <h5>**nu poți schimba materii fundamentale (F)</h5>}
                        { isMasterStudent && <h5>*poți schimba o materie cu o altă materie din același semestru calendaristic (1/3, respectiv 2), care are cel puțin tot atatea puncte credit ca cea pe care o schimbi</h5>}
                    </Col>
                    {(change[index] || choices[index]?.length !== 0) && <Col span={window.innerWidth > 535 ? 2 : 16}>
                        <FontAwesomeIcon icon={solid('arrow-right-arrow-left')} style={{ fontSize: 30 }} />
                    </Col>}
                    {(change[index] || choices[index]?.length !== 0) && <Col span={window.innerWidth > 535 ? 10 : 24}>
                        <h4>Listă materii de schimb:</h4>
                        <Select
                            className={styles.customSelect}
                            onChange={(value: any) => onSelect2(value, index)}
                            placeholder={t('optionals.option')}
                            disabled={editable[index] && choices[index]?.length > 0}
                            value={t('optionals.option')}
                        >
                            {destinations[index]?.filter((e: any) => choices[index].filter((c: any) => c.id === e.id).length === 0)?.map((option: any) => (
                                <Option key={option.id}>{option.name} {option.series ? "- " + option.series + (isMasterStudent ? ' ' + option.year?.charAt(1) : ''): ""}</Option>
                            ))}

                        </Select>
                        {choices[index] && choices[index].length > 0 && <DragDropContext
                            onDragEnd={(result: any) => handleOnDragEnd(result, index)}>
                            <DroppableStyles>
                                <Droppable droppableId={"elements"}>
                                    {(provided) => (
                                        <div
                                            className={"elements"}
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                        >
                                            <List
                                                bordered={false}
                                                itemLayout="vertical"
                                                size="large"
                                                loading={loading1 && !choices[index]}

                                                dataSource={choices[index] || []}
                                                renderItem={(item: any, pos: number) => (
                                                    <List.Item>
                                                        <Draggable
                                                            draggableId={item.id}
                                                            index={pos}
                                                            key={item.id}
                                                            isDragDisabled={editable[index]}
                                                        >
                                                            {(provided) => {
                                                                return (
                                                                    <div
                                                                        key={item.id}
                                                                        ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                    >
                                                                        <div className={styles.cardWrapper}>
                                                                            <CustomCard
                                                                                backgroundcolor={cardColor[index]}
                                                                                key={pos}
                                                                                className={styles.customCard}

                                                                            >
                                                                                <Row gutter={[5, 0]}>
                                                                                    <Col span={22} className={styles.flexCol}>
                                                                                        <div style={{ float: "left" }}>{item.name} {item.series ? "- " + item.series : ""}</div>
                                                                                    </Col>
                                                                                    <Col span={2} className={styles.flexCol}>
                                                                                        <CustomButton
                                                                                            onClick={() => handleDelete(item, index)}
                                                                                            title={t('optionals.deleteOption')}
                                                                                            style={{ background: 'transparent', border: 'none', outline: 'none', color: theme.black, boxShadow: 'none', padding: 0, marginTop: "0", float: "right" }}
                                                                                            disabled={editable[index]}
                                                                                        >
                                                                                            <FontAwesomeIcon icon={solid('circle-xmark')} />
                                                                                        </CustomButton>
                                                                                    </Col>
                                                                                </Row>
                                                                            </CustomCard>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            }}
                                                        </Draggable>
                                                    </List.Item>
                                                )}
                                            />
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DroppableStyles>
                        </DragDropContext>}

                        {!isMasterStudent && <h5>*daca nu găsești materia în lista de mai sus, poți utiliza formularul din cererea specială</h5>}

                    </Col>}

                    <Row style={{ justifyContent: "center" }} >
                        {requests.length >= 1 &&
                            <ConfirmationModal
                                nameButton={"Șterge cererea"}
                                modalText={"Sigur doriți să ștergeți cererea?"}
                                titleButton={''}
                                handleFunction={() => deleteRequest(requests[index].id, index)}
                                iconButton={null}
                                shapeButton={''}
                                typeButton={''}
                                disabledProp={false}
                                styleProp={{ background: theme.thirdColorACS, color: theme.white, marginTop: "2em", marginLeft: "auto", marginRight: "auto", marginBottom: "0em" }}
                            />
                        }
                        {save[index] && !editable[index] && <CustomButton
                            onClick={() => handleSave(index)}
                            backgroundcolor={theme.darkBlue}
                            textcolor={theme.white}
                            style={{ marginTop: "2em", marginLeft: "auto", marginRight: "auto", marginBottom: "0em" }}
                        >
                            Salvează cererea
                        </CustomButton>}
                        {!save[index] && editable[index] && choices[index]?.length > 0 && <CustomButton
                            onClick={() => handleEdit(index)}
                            backgroundcolor={theme.secondColorACS}
                            textcolor={theme.white}
                            style={{ marginTop: "2em", marginLeft: "auto", marginRight: "auto", marginBottom: "0em" }}
                        >
                            Editează cererea
                        </CustomButton>}
                    </Row>
                </Row>

            </div>
        );
    };

    const renderSpecialRequests = () => {
        return specialRequests.map((request: any, index: any) =>
            <div key={index}>
                <Row gutter={[8, 16]} key={index} style={{ marginBottom: '2em', justifyContent: 'center', background: theme.lightBlue, padding: "2em", borderRadius: "0.5em" }}>
                    <Col span={window.innerWidth > 535 ? 10 : 24}>
                        <h4>Cerere specială {index + 1}:</h4>
                        {options[0] && options[0].length > 0 && <Select
                            className={styles.customSelect}
                            onChange={(value: any) => onSpecialSelect(value, index)}
                            placeholder={t('optionals.option')}
                            disabled={editable2[index] && choices2[index] != null}
                            value={specialRequests[index] ? source2[index]?.name : t('optionals.option')}
                        >
                            {options[0]?.filter((e: any) => source.concat(source2).filter((c: any) => c?.id === e.id).length === 0)?.map((option: any) => (
                                <Option key={option.id}>{option.name}</Option>
                            ))}

                        </Select>}
                    </Col>
                    {(change2[index] || choices2[index]) && <Col span={window.innerWidth > 535 ? 2 : 16}>
                        <FontAwesomeIcon icon={solid('arrow-right-arrow-left')} style={{ fontSize: 30 }} />
                    </Col>}
                    {(change2[index] || choices2[index]) && <Col span={window.innerWidth > 535 ? 10 : 24}>
                        <h4>Listă materii de schimb:</h4>
                        <textarea
                            cols={window.innerWidth > 535 ? 50 : 25}
                            rows={window.innerWidth > 535 ? 1 : 2}
                            onChange={(event) => handleChangeInputs(event, index)}
                            value={choices2[index] ? choices2[index] : ''}
                            disabled={editable2[index]}
                        />
                        <h5>*completează facultatea, domeniul și numele materiilor de schimb, exact așa cum apar în planul de învățământ, în ordinea preferintelor</h5>
                    </Col>}

                    <Row style={{ justifyContent: "center" }} >
                    {specialRequests.length >= 1 &&
                        <ConfirmationModal
                            nameButton={"Șterge cererea"}
                            modalText={"Sigur doriți să ștergeți cererea?"}
                            titleButton={''}
                            handleFunction={() => deleteSpecialRequest(specialRequests[index].id, index)}
                            iconButton={null}
                            shapeButton={''}
                            typeButton={''}
                            disabledProp={false}
                            styleProp={{ background: theme.thirdColorACS, color: theme.white, marginTop: "1em", marginLeft: "auto", marginRight: "auto", marginBottom: "1em" }}
                        />
                    }
                    {save2[index] && !editable2[index] && <CustomButton
                        onClick={() => handleSpecialSave(index)}
                        backgroundcolor={theme.darkBlue}
                        textcolor={theme.white}
                        style={{ marginTop: "1em", marginLeft: "auto", marginRight: "auto", marginBottom: "2em" }}
                    >
                        Salvează cererea
                    </CustomButton>}
                    {!save2[index] && editable2[index] && choices2[index] != null && <CustomButton
                        onClick={() => handleSpecialEdit(index)}
                        backgroundcolor={theme.secondColorACS}
                        textcolor={theme.white}
                        style={{ marginTop: "1em", marginLeft: "auto", marginRight: "auto", marginBottom: "2em" }}
                    >
                        Editează cererea
                    </CustomButton>}
                </Row>
                </Row>
                
            </div>
        );
    };

    return available ? (
        <Layout>
            <Row style={{ justifyContent: "center", marginBottom: "2em" }}>
                <Col span={10}>
                    <h3>Alege materia pe care vrei să o schimbi din planul tău curent de învățământ.</h3>
                    {isMasterStudent ? <h3>Poți schimba maxim 3 materii in cei 2 ani de studii. 
                        Poți depune oricâte cereri de schimbare, în ordinea preferințelor (de sus in jos), iar sistemul le va accepta în limita permisă.
                    </h3> : <h3>Poți schimba maxim 5 materii in cei 4 ani de studii</h3>}
                    <h3 style={{color: 'red'}}>Mai poți schimba {remainingChanges} materii în total.</h3>
                </Col>
                <Col span={2} />
                <Col span={10}>
                    <h3>Alege o listă de materii, în ordinea preferințelor (de sus in jos), cu care ai vrea să schimbi materia selectată.</h3>
                </Col>
            </Row>

            {renderRequests()}

            {renderSpecialRequests()}
            <Row style={{ justifyContent: "center" }} gutter={[0, 8]}>
                <Col span={window.innerWidth > 535 ? 10 : 24}>
                    <CustomButton
                        onClick={addNewRequest}
                        className={styles.newRequestButton}
                        backgroundcolor={theme.primaryColorACS}
                        textcolor={theme.white}
                        style={{ marginTop: "0", marginLeft: "auto", marginRight: "auto" }}
                        disabled={requests.length + specialRequests.length >= 5 && !isMasterStudent}
                    >
                        Adaugă o nouă cerere de schimbare
                    </CustomButton>
                </Col>
                {!isMasterStudent && window.innerWidth > 535 && <Col span={2} />}
                {!isMasterStudent && <Col span={window.innerWidth > 535 ? 10 : 24}>
                    <CustomButton
                        onClick={addSpecialRequest}
                        className={styles.specialRequestButton}
                        backgroundcolor={theme.darkBlue2}
                        textcolor={theme.white}
                        style={{ marginTop: "0", marginLeft: "auto", marginRight: "auto" }}
                        disabled={requests.length + specialRequests.length >= 5 && !isMasterStudent}
                    >
                        Adaugă o cerere specială
                    </CustomButton>
                </Col>}
            </Row>
        </Layout>
    ) : ( 
        <Layout>
            <h2>{t('optionals.addChangeRequestsNotEnabled')}</h2>
        </Layout>
    );

}

export default ChangeSubject;