import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, List, Row, Select } from "antd";
import { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { SemesterEnum } from "../../Api";
import CustomButton from "../../CustomComponents/CustomButton";
import CustomCard from "../../CustomComponents/CustomCard";
import { theme } from "../../theme";
import { ChooseAction, DroppableStyles } from "../../utils/constants";
import { NOTIFICATION_TYPES, openNotification } from "../Notifications/NotificationsUtils";
import styles from "./Optionals.module.scss";
import { useIsMasterStudent } from "../../utils/utilFunctions";

const { Option } = Select;

const CustomList = (props: { data: any, handleSave: any, choices: any, type: any, semester: any, title: any }) => {
    const { t } = useTranslation();
    const [options, setOptions] = useState(props.data);
    const [choices, setChoices] = useState(new Array<any>());
    const [editable, setEditable] = useState(true);
    const [cardColor, setCardColor] = useState(theme.disabledGray);
    const isMasterStudent = useIsMasterStudent();
    
    useEffect(() => {
        setOptions(props.data);
        if (props.type === ChooseAction.SpecializationRepartition || props.type === ChooseAction.SubjectRepartition)
            setCardColor(theme.lightGreen2);
    }, [props]);

    const openChoicesErrorNotification = (_error: any) => {
        openNotification(
            t('optionals.error'),
            t('optionals.fetchChoicesError'),
            NOTIFICATION_TYPES.ERROR
        );
    }

    const { data, isLoading: loading1 } = useQuery(['getChoices', props.type],
        () => {
            if (props.type === ChooseAction.ChooseSpecialization || props.type === ChooseAction.SpecializationRepartition)
                return props.choices();
        }, {
        onError: openChoicesErrorNotification,
        refetchOnWindowFocus: false,
        refetchInterval: Infinity,
        onSuccess: (data) => {
            if (props.type === ChooseAction.SpecializationRepartition) {
                if (data) {
                    let rejected = data.rejectedSpecializations.map((e: any) => { return {...e, rejected: true}});
                    let accepted = data.acceptedSpecializations.map((e: any) => { return {...e, rejected: false}});
                    isMasterStudent ? setChoices(accepted.concat(rejected)) : setChoices(rejected.concat(accepted));
                }
            } else if (props.type === ChooseAction.ChooseSpecialization) {
                if (data) {
                    setChoices(data);
                }
            }
        }
    });

    const { data: choicesFirstSemesterFirstSubject } = useQuery(['getSpecializationChoicesFirstSemester', props.type, props.semester],
        () => {
            if ((props.type === ChooseAction.ChooseSubject || props.type === ChooseAction.SubjectRepartition) && props.semester === SemesterEnum.FirstSemester)
                return props.choices(SemesterEnum.FirstSemester);
        }, {
        onError: openChoicesErrorNotification,
        refetchOnWindowFocus: false,
        refetchInterval: Infinity,
        onSuccess: (data) => {
            if (props.type === ChooseAction.SubjectRepartition && props.semester === SemesterEnum.FirstSemester) {
                if (data) {
                    let rejected = data.rejectedOptionals.map((e: any) => { return {...e, rejected: true}});
                    let accepted = data.acceptedOptionals.map((e: any) => { return {...e, rejected: false}});
                    isMasterStudent ? setChoices(accepted.concat(rejected)) : setChoices(rejected.concat(accepted));

                }
            } else if (props.type === ChooseAction.ChooseSubject && props.semester === SemesterEnum.FirstSemester) {
                if (data) {
                    setChoices(data);
                }
            }
        }
    });

    const { data: choicesFirstSemesterSecondSubject } = useQuery(['getSpecializationChoicesFirstSemesterSecondSubject', props.type, props.semester],
        () => {
            if ((props.type === ChooseAction.ChooseSubject || props.type === ChooseAction.SubjectRepartition) && props.semester === SemesterEnum.FirstSemesterSecondSubject)
                return props.choices(SemesterEnum.FirstSemesterSecondSubject);
        }, {
        onError: openChoicesErrorNotification,
        refetchOnWindowFocus: false,
        refetchInterval: Infinity,
        onSuccess: (data) => {
            if (props.type === ChooseAction.SubjectRepartition && props.semester === SemesterEnum.FirstSemesterSecondSubject) {
                if (data) {
                    let rejected = data.rejectedOptionals.map((e: any) => { return {...e, rejected: true}});
                    let accepted = data.acceptedOptionals.map((e: any) => { return {...e, rejected: false}});
                    isMasterStudent ? setChoices(accepted.concat(rejected)) : setChoices(rejected.concat(accepted));


                }
            } else if (props.type === ChooseAction.ChooseSubject && props.semester === SemesterEnum.FirstSemesterSecondSubject) {
                if (data) {
                    setChoices(data);
                }
            }
        }
    });

    const { data: choicesSecondSemesterFirstSubject } = useQuery(['getSpecializationChoicesSecondSemester1', props.type, props.semester],
        () => {
            if ((props.type === ChooseAction.ChooseSubject || props.type === ChooseAction.SubjectRepartition) && props.semester === SemesterEnum.SecondSemesterFirstSubject)
                return props.choices(SemesterEnum.SecondSemesterFirstSubject);
        }, {
        onError: openChoicesErrorNotification,
        refetchOnWindowFocus: false,
        refetchInterval: Infinity,
        onSuccess: (data) => {
            if (props.type === ChooseAction.SubjectRepartition && props.semester === SemesterEnum.SecondSemesterFirstSubject) {
                if (data) {
                    let rejected = data.rejectedOptionals.map((e: any) => { return {...e, rejected: true}});
                    let accepted = data.acceptedOptionals.map((e: any) => { return {...e, rejected: false}});
                    isMasterStudent ? setChoices(accepted.concat(rejected)) : setChoices(rejected.concat(accepted));

                }
            } else if(props.type === ChooseAction.ChooseSubject && props.semester === SemesterEnum.SecondSemesterFirstSubject) {
                if (data) {
                    setChoices(data);
                }
            }
        }
    });

    const { data: choicesSecondSemesterSecondSubject } = useQuery(['getSpecializationChoicesSecondSemester2', props.type, props.semester],
        () => {
            if ((props.type === ChooseAction.ChooseSubject || props.type === ChooseAction.SubjectRepartition) && props.semester === SemesterEnum.SecondSemesterSecondSubject)
                return props.choices(SemesterEnum.SecondSemesterSecondSubject);
        }, {
        onError: openChoicesErrorNotification,
        refetchOnWindowFocus: false,
        refetchInterval: Infinity,
        onSuccess: (data) => {
            if (props.type === ChooseAction.SubjectRepartition && props.semester === SemesterEnum.SecondSemesterSecondSubject) {
                if (data) {
                    let rejected = data.rejectedOptionals.map((e: any) => { return {...e, rejected: true}});
                    let accepted = data.acceptedOptionals.map((e: any) => { return {...e, rejected: false}});
                    isMasterStudent ? setChoices(accepted.concat(rejected)) : setChoices(rejected.concat(accepted));
                    
                }
            } else if (props.type === ChooseAction.ChooseSubject && props.semester === SemesterEnum.SecondSemesterSecondSubject) {
                if (data) {
                    setChoices(data);
                }
            }
        }
    });
    const { data: choicesSecondSemesterThirdSubject } = useQuery(['getSpecializationChoicesSecondSemesterThirdSubject', props.type, props.semester],
        () => {
            if ((props.type === ChooseAction.ChooseSubject || props.type === ChooseAction.SubjectRepartition) && props.semester === SemesterEnum.SecondSemesterThirdSubject)
                return props.choices(SemesterEnum.SecondSemesterThirdSubject);
        }, {
        onError: openChoicesErrorNotification,
        refetchOnWindowFocus: false,
        refetchInterval: Infinity,
        onSuccess: (data) => {
            if (props.type === ChooseAction.SubjectRepartition && props.semester === SemesterEnum.SecondSemesterThirdSubject) {
                if (data) {
                    let rejected = data.rejectedOptionals.map((e: any) => { return {...e, rejected: true}});
                    let accepted = data.acceptedOptionals.map((e: any) => { return {...e, rejected: false}});
                    
                    isMasterStudent ? setChoices(accepted.concat(rejected)) : setChoices(rejected.concat(accepted));

                }
            } else if (props.type === ChooseAction.ChooseSubject && props.semester === SemesterEnum.SecondSemesterThirdSubject) {
                if (data) {
                    setChoices(data);
                }
            }
        }
    });

    const handleOnDragEnd = (result: any) => {
        const items = Array.from(choices);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        setChoices(items);
    };

    const handleDelete = (item: any) => {
        let pos = -1;
        for (let i = 0; i < choices.length; i++) {
            if (choices[i].id === item.id) {
                pos = i;
            }
        }
        if (pos !== -1) {
            const items = Array.from(choices);
            items.splice(pos, 1);
            setChoices(items);
        }
    }

    const handleEdit = () => {
        setEditable(false);
        setCardColor(theme.white);
    }

    const handleAdd = () => {
        if (editable) {
            setEditable(false);
            setCardColor(theme.white);
        }
        else {
            setEditable(true);
            setCardColor(theme.disabledGray);
        }
    }

    const handleSave = () => {
        setEditable(!editable);
        setCardColor(theme.disabledGray);
        let body = [];
        for (let i = 0; i < choices.length; i++) {
            body.push(choices[i].id);
        }

        if (body.length > 0) {
            if (props.type === ChooseAction.ChooseSpecialization) {
                props.handleSave(body).then(() => {
                    openNotification(
                        t('optionals.saveOption'),
                        t('optionals.saveChangesMessage'),
                        NOTIFICATION_TYPES.SUCCESS
                    );
                }).catch((_error: any) => {
                    openNotification(
                        t('optionals.error'),
                        t('optionals.saveChangesError'),
                        NOTIFICATION_TYPES.ERROR
                    );
                });
            } else if (props.type === ChooseAction.ChooseSubject) {
                props.handleSave(props.semester, body).then(() => {
                    openNotification(
                        t('optionals.saveOption'),
                        t('optionals.saveChangesMessage'),
                        NOTIFICATION_TYPES.SUCCESS
                    );
                }).catch((_error: any) => {
                    openNotification(
                        t('optionals.error'),
                        t('optionals.saveChangesError'),
                        NOTIFICATION_TYPES.ERROR
                    );
                });
            }
        } else {
            openNotification(
                t('optionals.error'),
                t('optionals.atLeastOneOptionRequirement'),
                NOTIFICATION_TYPES.ERROR
            );
        }
    }

    const onSelect = (value: any) => {
        const id = value.split("_");
        let auxPreferences = [];
        let aux;
        let pos = -1;
        for (let i = 0; i < options.length; i++) {
            if (id[0] === options[i].id) {
                aux = options[i];
                pos = i;
            }
            auxPreferences.push(options[i]);
        }
        if (pos !== -1)
            auxPreferences.splice(pos, 1);
        setChoices(choices.concat(aux));
    }

    return (
        <div className={styles.listWrapper}>
            {choices && choices?.length > 0 && <h3>{props.title}</h3>}
            <div className={styles.customList}>
                {!editable && <Select
                    className={styles.customSelect}
                    onChange={onSelect}
                    placeholder={t('optionals.option')}
                    value={t('optionals.option')}
                >
                    {options?.filter((e: any) => choices.filter(c => c.id === e.id).length === 0)?.map((option: any) => (
                        <Option key={option.id}>
                            {option.name + (option.specialization ? (option.year?.charAt(0) === 'L' ? 
                                                            " (" + option.specialization?.name?.substring(0, 2) + ")" : 
                                                            " - " + option.specialization?.name + " " + option.year?.charAt(1)) : '')}
                        </Option>
                        ))}
                </Select>}
                {choices && choices?.length > 0 ?
                    (<DragDropContext onDragEnd={handleOnDragEnd}>
                    <DroppableStyles>
                    <Droppable droppableId={"elements"}>
                    {(provided) => (
                        <div
                            className={"elements"}
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                        >
                        {choices && choices?.length > 0 &&
                            <List
                                bordered={false}
                                itemLayout="vertical"
                                size="large"
                                loading={loading1 && !choices}
                                dataSource={choices || []}
                                renderItem={(item: any, index: number) => (
                                <List.Item>
                                <Draggable
                                    draggableId={item.id}
                                    index={index}
                                    key={item.id}
                                    isDragDisabled={editable}
                                >
                                    {(provided) => {
                                    return (
                                    <div
                                        key={item.id}
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                    >
                                        <div className={styles.cardWrapper}>
                                            <CustomCard
                                                backgroundcolor={!item.rejected ? cardColor : theme.lightBlue}
                                                key={index}
                                                className={styles.customCard}

                                            >
                                                <Row gutter={[5, 0]}>
                                                    <Col span={22} className={styles.flexCol}>
                                                        <div style={{ float: "left" }}>
                                                            {(props.type === ChooseAction.SpecializationRepartition || props.type === ChooseAction.SubjectRepartition) && !item.rejected ? <br></br> : ''}
                                                            {item.name + (item.specialization ? " (" + item.specialization?.name?.substring(0, 2) + ")" : '')}
                                                            <br></br>
                                                            {(props.type === ChooseAction.SpecializationRepartition || props.type === ChooseAction.SubjectRepartition) && !item.rejected ? <br></br> : ''}
                                                            {item.rejected ? 'Respins, ultima medie -  ' + item.lastGrade : ''}
                                                         </div>
                                                    </Col>
                                                    {props.type !== ChooseAction.SpecializationRepartition && props.type !== ChooseAction.SubjectRepartition && <Col span={2} className={styles.flexCol}>
                                                        <CustomButton
                                                            onClick={() => handleDelete(item)}
                                                            title={t('optionals.deleteOption')}
                                                            style={{ background: 'transparent', border: 'none', outline: 'none', color: theme.black, boxShadow: 'none', padding: 0, marginTop: "0", float: "right" }}
                                                            disabled={editable}
                                                        >
                                                            <FontAwesomeIcon icon={solid('circle-xmark')} />
                                                        </CustomButton>
                                                    </Col>}
                                                </Row>
                                            </CustomCard>
                                        </div>
                                    </div>
                                    );}}
                                </Draggable>
                                </List.Item>
                                )}
                            />}
                        {provided.placeholder}
                        </div>
                    )}
                    </Droppable>
                    </DroppableStyles>
                    </DragDropContext>) : null}
                {choices && choices?.length > 0 && editable && (props.type !== ChooseAction.SpecializationRepartition && props.type !== ChooseAction.SubjectRepartition) && <CustomButton
                    onClick={handleEdit}
                    backgroundcolor={theme.secondColorACS}
                    textcolor={theme.white}
                    style={{ marginTop: "2em", marginLeft: "auto", marginRight: "auto" }}
                >
                    {t('optionals.editOptions')}
                </CustomButton>}
                {choices?.length <= 0 && (props.type !== ChooseAction.SpecializationRepartition && props.type !== ChooseAction.SubjectRepartition) && editable &&
                    <CustomButton
                        onClick={handleAdd}
                        backgroundcolor={theme.secondColorACS}
                        textcolor={theme.white}
                        style={{ marginTop: "2em", marginLeft: "auto", marginRight: "auto" }}
                    >
                        {t('optionals.addOptions')}
                    </CustomButton>}
                {(choices && choices?.length > 0 && !editable && (props.type !== ChooseAction.SpecializationRepartition && props.type !== ChooseAction.SubjectRepartition)) && <CustomButton
                    onClick={handleSave}
                    backgroundcolor={theme.primaryColorACS}
                    textcolor={theme.white}
                    style={{ marginTop: "2em", marginLeft: "auto", marginRight: "auto" }}
                >
                    {t('optionals.saveOptions')}
                </CustomButton>}
            </div>
        </div>
    );
}

export default CustomList;