/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StudentYearEnum,
    StudentYearEnumFromJSON,
    StudentYearEnumFromJSONTyped,
    StudentYearEnumToJSON,
} from './StudentYearEnum';

/**
 * 
 * @export
 * @interface SpecializationReportDTO
 */
export interface SpecializationReportDTO {
    /**
     * 
     * @type {string}
     * @memberof SpecializationReportDTO
     */
    name?: string | null;
    /**
     * 
     * @type {StudentYearEnum}
     * @memberof SpecializationReportDTO
     */
    studentYear?: StudentYearEnum;
    /**
     * 
     * @type {string}
     * @memberof SpecializationReportDTO
     */
    domain?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SpecializationReportDTO
     */
    series?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SpecializationReportDTO
     */
    averageGrade?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof SpecializationReportDTO
     */
    optionsSpecialization?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof SpecializationReportDTO
     */
    repartitionSpecialization?: Array<string> | null;
}

export function SpecializationReportDTOFromJSON(json: any): SpecializationReportDTO {
    return SpecializationReportDTOFromJSONTyped(json, false);
}

export function SpecializationReportDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): SpecializationReportDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'studentYear': !exists(json, 'studentYear') ? undefined : StudentYearEnumFromJSON(json['studentYear']),
        'domain': !exists(json, 'domain') ? undefined : json['domain'],
        'series': !exists(json, 'series') ? undefined : json['series'],
        'averageGrade': !exists(json, 'averageGrade') ? undefined : json['averageGrade'],
        'optionsSpecialization': !exists(json, 'optionsSpecialization') ? undefined : json['optionsSpecialization'],
        'repartitionSpecialization': !exists(json, 'repartitionSpecialization') ? undefined : json['repartitionSpecialization'],
    };
}

export function SpecializationReportDTOToJSON(value?: SpecializationReportDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'studentYear': StudentYearEnumToJSON(value.studentYear),
        'domain': value.domain,
        'series': value.series,
        'averageGrade': value.averageGrade,
        'optionsSpecialization': value.optionsSpecialization,
        'repartitionSpecialization': value.repartitionSpecialization,
    };
}

