import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { SemesterEnum, StudentYearEnum } from "../../Api";
import { useState } from "react";
import Layout from "../../Containers/Layout";
import { ChooseSubject, GetAllSubjects, GetSubjectChoices } from "../../Requests/subject-requests";
import { ChooseAction } from "../../utils/constants";
import { NOTIFICATION_TYPES, openNotification } from "../Notifications/NotificationsUtils";
import CustomList from "./CustomList";
import { useIsMasterStudent } from "../../utils/utilFunctions";


const ChooseOptionals = () => {
    const { t } = useTranslation();
    const year = StudentYearEnum.L4;
    const isMasterStudent = useIsMasterStudent();
    const [available, setIsAvailable] = useState(true);

    const openSubjectErrorNotification = (_error: any) => {
        openNotification(
            t('optionals.error'),
            t('optionals.fetchOptionalsError'),
            NOTIFICATION_TYPES.ERROR
        );
    }

    const { data: dataFirstSemesterFirstSubject } = useQuery(['getDataFirstSemesterFirstSubject'],
        () => {
            return GetAllSubjects(SemesterEnum.FirstSemester, year);
        }, {
        onError: (error) => {
            const errorWithResponse = error as {status?: number};
            if (errorWithResponse.status) {
                const statusCode = errorWithResponse.status;
                if (statusCode === 403) {
                    setIsAvailable(false);
                } else {
                    openSubjectErrorNotification(error);
                }
            } else {
                openSubjectErrorNotification(error);
            }
        },
        onSuccess: () => {
            setIsAvailable(true);
        }
    });

    const { data: dataFirstSemesterSecondSubject } = useQuery(['getDataFirstSemesterSecondSubject'],
        () => {
            return GetAllSubjects(SemesterEnum.FirstSemesterSecondSubject, year);
        }, {
        onError: openSubjectErrorNotification,
    });

    const { data: dataSecondSemesterFirstSubject } = useQuery(['getDataSecondSemesterFirstSubject'],
        () => {
            return GetAllSubjects(SemesterEnum.SecondSemesterFirstSubject, year);
        }, {
        onError: openSubjectErrorNotification,
    });

    const { data: dataSecondSemesterSecondSubject } = useQuery(['getDataSecondSemesterSecondSubject'],
        () => {
            return GetAllSubjects(SemesterEnum.SecondSemesterSecondSubject, year);
        }, {
        onError: openSubjectErrorNotification,
    });

    const { data: dataSecondSemesterThirdSubject } = useQuery(['getDataSecondSemesterThirdSubject'],
        () => {
            return GetAllSubjects(SemesterEnum.SecondSemesterThirdSubject, year);
        }, {
        onError: openSubjectErrorNotification,
    });

    return available ? (
        <Layout>
            <h1>{t('optionals.chooseOptionals')}</h1>

            {!!(dataFirstSemesterFirstSubject?.length) && dataFirstSemesterFirstSubject?.length > 0 ?
                <div>
                    { !isMasterStudent && <h4>Adaugă opțiunile dorite în ordinea preferințelor. Este recomandat să adaugi mai multe opțiuni pentru siguranță.</h4>}
                    { !isMasterStudent && <h4 style={{ marginBottom: "30px" }}>Dacă nu completezi opțiunile, vei fi repartizat automat unde mai rămân locuri.</h4>}
                    { isMasterStudent && <h4>Adaugă opțiunile dorite în ordinea preferințelor (alege din lista si drag-and-drop pentru reordonare).</h4>}
                    { isMasterStudent && <h4 style={{color: 'green'}}>Adauga foarte multe optiuni pentru siguranta (daca nu te incadrezi la niciuna dintre cele completate vei fi repartizat automat unde mai rămân locuri).</h4>}
                    { isMasterStudent && <h4 style={{color: 'red',  marginBottom: "30px"}}>Materiile rezultate in urma repartitiei nu vor putea fi schimbate ulterior, deci este important sa precizezi toate variantele dorite.</h4>}
                </div>
                :
                <div>
                    <h2>Pentru anul acesta nu ai de ales opționale!</h2>
                    { isMasterStudent && <h4>Programe de masterat fără opționale pe anul 1, semestrul 1: ABD, GMRV, ISI, SAS, QC</h4>}
                    { isMasterStudent && <h4>Programe de masterat fără opționale pe anul 1, semestrul 2: ABD, EGOV, GMRV, MTI, QC</h4>}
                    { isMasterStudent && <h4>Programe de masterat fără opționale pe anul 2, semestrul 1: ABD, GMRV, SPF, QC</h4>}
                </div>
            }
            {dataFirstSemesterFirstSubject && dataFirstSemesterFirstSubject?.length > 0
             && dataFirstSemesterSecondSubject && dataFirstSemesterSecondSubject?.length === 0 
             ? <h2>{t('optionals.semester1')}</h2> 
             : dataFirstSemesterFirstSubject && dataFirstSemesterFirstSubject?.length > 0
             && dataFirstSemesterSecondSubject && dataFirstSemesterSecondSubject?.length > 0 
             ? <h2>{t('optionals.semester1FirstOptional')}</h2>
            : null}
            
            
            {dataFirstSemesterFirstSubject && dataFirstSemesterFirstSubject?.length > 0 ?
                <div>
                    <CustomList
                        data={dataFirstSemesterFirstSubject}
                        handleSave={ChooseSubject}
                        choices={GetSubjectChoices}
                        type={ChooseAction.ChooseSubject}
                        semester={SemesterEnum.FirstSemester}
                        title={null}
                    />
                </div>
                : null}
            {dataFirstSemesterSecondSubject && dataFirstSemesterSecondSubject?.length > 0 ?
                <div>
                    <h2>{t('optionals.semester1SecondOptional')}</h2>
                    <CustomList
                        data={dataFirstSemesterSecondSubject}
                        handleSave={ChooseSubject}
                        choices={GetSubjectChoices}
                        type={ChooseAction.ChooseSubject}
                        semester={SemesterEnum.FirstSemesterSecondSubject}
                        title={null} />
                </div>
                : null}

            {dataSecondSemesterFirstSubject && dataSecondSemesterFirstSubject?.length > 0
            && dataSecondSemesterSecondSubject && dataSecondSemesterSecondSubject?.length === 0 
                ? <h2>{t('optionals.semester2')}</h2>
                : dataSecondSemesterFirstSubject && dataSecondSemesterFirstSubject?.length > 0
                && dataSecondSemesterSecondSubject && dataSecondSemesterSecondSubject?.length > 0 ?
                <h2>{t('optionals.semester2FirstOptional')}</h2>
            : null}

            {dataSecondSemesterFirstSubject && dataSecondSemesterFirstSubject?.length > 0 ?
                <div>
                    
                    <CustomList
                        data={dataSecondSemesterFirstSubject}
                        handleSave={ChooseSubject}
                        choices={GetSubjectChoices}
                        type={ChooseAction.ChooseSubject}
                        semester={SemesterEnum.SecondSemesterFirstSubject}
                        title={null}
                    />
                </div>
                : null}

            {dataSecondSemesterSecondSubject && dataSecondSemesterSecondSubject?.length > 0 ?
                <div>
                    <h2>{t('optionals.semester2SecondOptional')}</h2>
                    <CustomList
                        data={dataSecondSemesterSecondSubject}
                        handleSave={ChooseSubject}
                        choices={GetSubjectChoices}
                        type={ChooseAction.ChooseSubject}
                        semester={SemesterEnum.SecondSemesterSecondSubject}
                        title={null} />
                </div>
                : null}

            {dataSecondSemesterThirdSubject && dataSecondSemesterThirdSubject?.length > 0 ?
                <div>
                    <h2>{t('optionals.semester2ThirdOptional')}</h2>
                    <CustomList
                        data={dataSecondSemesterThirdSubject}
                        handleSave={ChooseSubject}
                        choices={GetSubjectChoices}
                        type={ChooseAction.ChooseSubject}
                        semester={SemesterEnum.SecondSemesterThirdSubject}
                        title={null} />
                </div>
                : null}
        </Layout>
    ) : ( 
        <Layout>
            <h2>{t('optionals.optionalsSelectionNotEnabled')}</h2>
        </Layout>
    ) ;
}

export default ChooseOptionals;